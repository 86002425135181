import * as React from 'react';
import { render } from 'react-dom';
import { Layout, Space, Steps } from 'antd';
import { useState } from 'react';
import { Button, Form, Input, Select, Upload, Modal, Spin, Alert, Tooltip, Typography, Table } from 'antd';
import { Col, Divider, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CopyFilled, BarcodeOutlined, RightCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Tesseract, { PSM } from 'tesseract.js';
import vision from "react-cloud-vision-api";
import { type } from '@testing-library/user-event/dist/type';


const { Header, Content } = Layout;
const { Title, Text } = Typography;


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Main() {

  //Define state
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [productData, setProductData] = useState('');
  const [productDataBrand, setProductDataBrand] = useState('');
  const [productDataEAN, setProductDataEAN] = useState('');
  const [productDataBrandEAN, setProductDataBrandEAN] = useState('EAN');
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDeliveryTime, setProductDeliveryTime] = useState('');
  const [productStock, setProductStock] = useState('');
  const [productDeliveryCost, setProductDeliveryCost] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productApplication, setProductApplication] = useState('');
  const [productExternalId, setProductExternalId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isLoadingFetch, setLoadingFetch] = useState(false);
  const [isPricesLoading, setPricesLoading] = useState(false);
  const [prices, setPrices] = useState([])
  const [isBarcodeOpened, setBarcodeOpened] = useState(false)
  const [isBarcodeDecoded, setBarcodeDecoded] = useState(false)
  const [progress, setProgress] = useState(0);
  const [laximoOem, setLaximoOem] = useState([])
  const [base64Image, setBase64Image ] = useState('')
  const [azureImageUrl, setAzureImageUrl ] = useState('')
  const [productType, setProductType ] = useState(false)
  const [productTypeCat, setProductTypeCat ] = useState('')
  const [productTypeName, setProductTypeName ] = useState('')
  const [current, setCurrent] = useState(0);
  const [attributeSet, setAttributeSet] = useState('21');
  const onChangeSteps = (value) => {
    console.log('onChange:', value);
    setCurrent(value);
    setProductType(false)
  };

  //Define forms
  const [form] = Form.useForm();
  const [yqform] = Form.useForm();

  //Get KH code from the session storage
  const kh_code = sessionStorage.getItem('kh_code');

  //Handle photo operations
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  //Handle added files to the list
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  
  };

  //Handle file upload based on Base64 request to Node.js API
  const handleUploadFile = async ({file, onSuccess, onError, onProgress}) => {
    const image = await getBase64(file)
    setBase64Image(image)

    var fileExtension = ""

        if(file['type'] == "image/jpeg") {
            fileExtension = ".jpeg"
        }
        else if(file['type'] == "image/jpg") {
            fileExtension = ".jpg"
        }
        else if(file['type'] == "image/png") {
            fileExtension = ".png"
        }
        else if(file['type'] == "image/gif") {
            fileExtension = ".gif"
        }

    setAzureImageUrl("https://images.vip-intercars.bg/"+file['uid']+fileExtension)
    const dataBody = {"image": image, "name":file['uid'], "type": file['type']}

    axios.post("https://images.vip-intercars.bg/uploadss", dataBody, {onUploadProgress: event => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      setProgress(percent);
      if (percent === 100) {
        setTimeout(() => setProgress(0), 1000);
      }
      onProgress({ percent: (event.loaded / event.total) * 100 });
    }}).then(result => onSuccess("Ok")).catch(error => onError("Error"))
    //console.log(dataBody)
    }

  //Handle OCR image Google Vision
  const OCRHandle = () => {

    vision.init({ auth: 'AIzaSyAL-5PIJgJnmfx695Z7Y2poQPsziXHiQEk'})

    const req = new vision.Request({
      image: new vision.Image({
        base64: base64Image,
      }),
      features: [
        new vision.Feature('TEXT_DETECTION', 4)
      ]
    })

    // send single request
        vision.annotate(req).then((res) => {
          // handling response
          console.log(JSON.stringify(res.responses))
        }, (e) => { 
          console.log('Error: ', e)
        })

  }

  //Handle OCR image Google Vision
  const OCRHandleAzure = () => {

    const requestUrl = 'https://dvasilkovImage.cognitiveservices.azure.com/computervision/imageanalysis:analyze?api-version=2023-02-01-preview&features=read&language=en&gender-neutral-caption=False'

    let config = {
      headers: {
        'Content-type': 'application/json',
        'Ocp-Apim-Subscription-Key':'276ffadcbdf94413bdddc40f5b616df9'
      }
    }

    const bodyRequest = {'url':azureImageUrl}

    axios.post(requestUrl, bodyRequest, config).then(result => console.log(result.data)).catch(error => console.log(error))

  }

  //LAXIMO to get data and Prices
  const getProductData = () => {

    //Check if there is any value in manufacturer and number, if not we stop before sending request
    if(!productData || !productDataBrand) {
      render(<Alert message="Моля, попълнете марка и номер!" type="warning" className='infobar' closable />, document.getElementById('fetching_result'))
      return
    }

    //Enable loading spinner
    setLoadingFetch(true)
    //Construct the request body
    const requestProductData = {"number": productData, "brand":productDataBrand}
    //Define empty array to keep replacements
    const replacementArray = []
    //Define variable to keep product name if there is information
    var productName = ''
    //Request to the API endpoint 
    axios.post("https://services.vip-intercars.bg/findOEM", requestProductData).then(result => {
    //Define new DOMParser
    const parser = new DOMParser();
    const xml = parser.parseFromString(result.data, 'text/xml');
    //Transform the response to the proper XML format
    const xmlReturn = parser.parseFromString(xml.documentElement.textContent, 'text/xml');
    //Get replacement section from the response
    const replacementData = xmlReturn.getElementsByTagName('replacement')
    //Get propert section where the data contains
    const productNameXml = xmlReturn.getElementsByTagName('property')
    //For loop to check locale and put set value of productName when locale is Polish language
    for(var y = 0; y < productNameXml.length; y++) {

      const locale = productNameXml[y].getAttribute('locale')
      const value = productNameXml[y].getAttribute('value')

      if(value.length > 0 && locale === "pl_PL") {

        productName = value

      }
      
    }

    //Put brand and number of the search as a first OEM number
    replacementArray.push({"number": productData, "manufacturer":productDataBrand})

    for(var i = 0; i < replacementData.length; i++) {

      //Push each OEM number to the array in the proper format
      replacementArray.push({"number":xmlReturn.getElementsByTagName('replacement')[i].getElementsByTagName('detail')[0].getAttribute('formattedoem'), "manufacturer":xmlReturn.getElementsByTagName('replacement')[i].getElementsByTagName('detail')[0].getAttribute('manufacturer')})
      
    }

    //Set state to keep replacement array information
    setLaximoOem(replacementArray)

    //console.log(xmlReturn.getElementsByTagName('replacement')[0].getElementsByTagName('detail')[0].getAttribute('formattedoem'))

    //Set value of product name and OEM numbers
    form.setFields([{name: 'oem', value: replacementArray},{name: ['product', 'name'], value: productName}])
    //Set value for the product name state
    setProductName(productName)
    //Disable loading spinner
    setLoadingFetch(false)

    if(replacementData.length > 0) {
      render(<Alert message="OEM/заместващите номера са добавени успешно!" type="success" className='infobar' closable />, document.getElementById('fetching_result'))
    }
    else {
      render(<Alert message="За съжаление не успяхме да намерим този номер на продукта в нашата база данни!" type="warning" className='infobar' closable />, document.getElementById('fetching_result'))
    }
  
  }).catch(error => {console.log(error)})

  setPricesLoading(true)

  const requestPricesData= {"number": productData.replaceAll(' ', '')}

  axios.post("https://services.vip-intercars.bg/gerPrices", requestPricesData).then(result => {

  //console.log(result.data)


  
      setPrices([])
      setPricesLoading(false)

  }).catch(error => {console.log(error)})


  };

  //Decode barcode based on YQ service and prefil manufacturer and number
  const getEAN = (numberEAN) => {


    //Change the state of camera to close and enable spinner for loading
    setBarcodeOpened(false);
    setLoadingFetch(true)

    //Construct of the request body
    const requestProductData = {"number": numberEAN, "brand":productDataBrandEAN}
    //Define empty array to keep replacements
    const replacementArray = []
    //Requst to the API endpoint
    axios.post("https://services.vip-intercars.bg/findOEM", requestProductData).then(result => {
    //Define new DOMParser to parse the XML from the response
    const parser = new DOMParser();
    const xml = parser.parseFromString(result.data, 'text/xml');
    //Decode the text and convert it in the proper XML format
    const xmlReturn = parser.parseFromString(xml.documentElement.textContent, 'text/xml');
    //Get replacement section from XML
    const replacementData = xmlReturn.getElementsByTagName('replacement')
    //Push each record to the array in proper structure
    for(var i = 0; i < replacementData.length; i++) {

      replacementArray.push({"number":xmlReturn.getElementsByTagName('replacement')[i].getElementsByTagName('detail')[0].getAttribute('formattedoem'), "manufacturer":xmlReturn.getElementsByTagName('replacement')[i].getElementsByTagName('detail')[0].getAttribute('manufacturer')})
      
    }
    //Disable the spinner
    setLoadingFetch(false)

    if(replacementData.length > 0) {
      //Render success message
      render(<Alert message={"Баркодът е декодиран успешно!! Баркод EAN:"+numberEAN} type="success" className='infobar' closable />, document.getElementById('fetching_result'))
      //Set product number to the state
      setProductData(replacementArray[0].number)
      //Set product brand to the state
      setProductDataBrand(replacementArray[0].manufacturer)
      //Set state that barcode has been successfully decoded
      setBarcodeDecoded(true)
      //Set proper form values
      yqform.setFields([{name: ['product', 'fetchname'], value: replacementArray[0].number},{name: ['product', 'fetchbrand'], value: replacementArray[0].manufacturer}])
      

    }
    else {
      //Render warning message that we don't have any information for this number
      render(<Alert message="За съжаление не успяхме да намерим този номер на продукта в нашата база данни!" type="warning" className='infobar' closable />, document.getElementById('fetching_result'))
    }
  
  }).catch(error => {
    //Render error in the console
    console.log(error)}
    )};

  const handleProductData = (event) => {
    setProductData(event.target.value)
    //console.log(event.target.value);
  };

  const handleProductDataBrand = (event) => {
    setProductDataBrand(event.target.value)
  }

  const handleProducName = (event) => {
    setProductName(event.target.value)
    //console.log(event.target.value);
  };

  const handleProducPrice= (event) => {
    setProductPrice(event.target.value.replace(',', '.').replace(' ', ''))
    //console.log(event.target.value.replace(',', '.'));
  };

  const handleProducDeliveryTime= (event) => {
    setProductDeliveryTime(event.target.value)
    //console.log(event.target.value);
  };

  const handleProducStock= (event) => {
    setProductStock(event.target.value)
    //console.log(event.target.value);
  };

  const handleProducDeliveryCost= (event) => {
    setProductDeliveryCost(event.target.value)
    //console.log(event.target.value);
  };

  const handleProducDescription= (event) => {
    setProductDescription(event.target.value)
    //console.log(event.target.value);
  };

  const handleProductApplication= (event) => {
    setProductApplication(event.target.value)
    //console.log(event.target.value);
  };

  const handleProductExternalId= (event) => {
    setProductExternalId(event.target.value)
    //console.log(event.target.value);
  };

  const handleCopyName = (name) => {
    form.setFields([{name: ['product', 'name'], value: name}])
    setProductName(name)
  }

  const handleCopyPrice= (price) => {
    form.setFields([{name: ['product', 'price'], value: price.replace(',', '.').replace(' ','')}])
    setProductPrice(price.replace(',', '.').replace(' ', ''))
  }

  const validateMessages = {
    required: 'Полетата отбелязани със звездичка са задължителни!'
  };

  const onFinish = (values: any) => {

    setLoading(true)

    const allFiles = fileList;
    const imagesUrl = "https://images.vip-intercars.bg/"

    const allImages = []

    allFiles.forEach(element => {
      if(element.type === "image/jpeg") {
        allImages.push(imagesUrl+element.uid+".jpeg")
      }
      else if(element.type === "image/jpg") {
        allImages.push(imagesUrl+element.uid+".jpg")
      }
      else if(element.type === "image/png") {
        allImages.push(imagesUrl+element.uid+".png")
      }
      else if(element.type === "image/gif") {
        allImages.push(imagesUrl+element.uid+".gif")
      }
    })

    const bodyRequest = [{
      "product": {
      "sku": "",
      "kh_code": kh_code,
      "name": productName,
      "attribute_set_id": attributeSet,
      "price": productPrice,
      "status": 1,
      "visibility": 4,
      "type_id": "simple",
      "weight": "1",
      "extension_attributes": {
      "category_links": [
      {
      "position": 0,
      "category_id": productTypeCat
      }
      ],
      "stock_item": {
      "qty": productStock,
      "is_in_stock": true
      },
      "oem_number":values.oem,
      "image_urls": allImages
      },
      "custom_attributes": [
      {
      "attribute_code": "vehicle_disassembled_from",
      "value": "WAWVINOFTHEDONOR5"
      },
      {
      "attribute_code": "kh_code",
      "value": kh_code
      },
      {
      "attribute_code": "description",
      "value": productDescription
      },
      {
      "attribute_code": "application",
      "value": productApplication
      },
      {
      "attribute_code": "external_id",
      "value": productExternalId
      },
      {
      "attribute_code": "tax_class_id",
      "value": 2
      },
      {
        "attribute_code": "delivery_cost",
        "value": productDeliveryCost
      },
      {
          "attribute_code": "shipment_within",
          "value": productDeliveryTime
      }
      ]
      }
      }]

      axios.post("https://services.vip-intercars.bg/marketplace-product", bodyRequest).then( result => {
        setLoading(false)
        render(<Alert message="Поздравления! Продуктът е добавен успешно и скоро ще бъде видим в списъка с продукти!" type="success" />, document.getElementById('notification'))
        form.resetFields();
        yqform.resetFields();
        setFileList([]);
        setProductTypeName('');
    }).catch(error => {
      setLoading(false)
      if(error.status === 403) {
        render(<Alert message='Нещо се обърка! Моля, опитайте отново по-късно' description={<a href="mailto:marketplace_support@intercars.eu">marketplace_support@intercars.eu</a>} type="error" />, document.getElementById('notification'))
      }
      else {
        render(<Alert message='Нещо се обърка! Моля, опитайте отново по-късно' description={<a href="mailto:marketplace_support@intercars.eu">marketplace_support@intercars.eu</a>} type="error" />, document.getElementById('notification'))
      }
      
    })

      

      //console.log(bodyRequest)
    //console.log('Received values of form:', values);
  };


  const columnsPricesTable = [
    {
      title: 'Производител',
      dataIndex: 'brand',
      key: 'brand'
    },
    {
      title: 'Име на продукта',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Text>{record.name} <Tooltip title="Копирай"><CopyFilled style={{ fontSize: '24px'}} onClick={() => handleCopyName(record.name)}/></Tooltip></Text>
    },
    {
      title: 'ICSA цена на дребно',
      dataIndex: 'price',
      key: 'price'
    }]

  const { TextArea } = Input;
  const style: React.CSSProperties = { padding: '20px 20px 20px 20px' };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Добави
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {productType ? 
    <Layout className='main'>
      <Header className='header'><img className="headerLogo" alt='Logo' src="https://bg.marketplace.intercars.eu/media/logo/default/logo_Marketplace.jpg"></img></Header>
      <Content style={style}>
      <Steps
        current={current}
        onChange={onChangeSteps}
        items={[
          {
            title: 'Избор на тип продукт',
            description: productTypeName,
          },
          {
            title: 'Информация за продукта',
            description: 'Информация за продукта',
          }
        ]}
      />
      <Divider orientation="left"><Title level={4}>Проверете нашата база данни</Title></Divider>
      <Alert showIcon message="Ако предоставите името на производителя на стоките и каталожния номер на частта, ние първоначално ще проверим нашата база данни и ще попълним формуляра с данните" type="info" closable className='infobar' />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
              {isLoadingFetch ? <div id="progress_fetch_data" className="spin-center"><Spin size="large" /></div> : <div id="progress_fetch_data"></div>}
              <div id="fetching_result"></div>
              </Col>
            </Row>
            {isBarcodeOpened ? <div className='center-camera'><BarcodeScannerComponent
        onUpdate={(err, result) => {
          if (result) { 
            setProductDataEAN(result.getText())
            console.log(result.getText())
            if(result.getText().length > 0){
              getEAN(result.getText());
            }
            
        }}}
        >
          </BarcodeScannerComponent> </div> : ""}
      <Form layout="vertical" form={yqform} validateMessages={validateMessages} >    
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <Form.Item className='prefildataplace' required>
                  <Form.Item name={['product', 'fetchbrand']} rules={[{ required: true }]}>
                  <Input size="large" onChange={handleProductDataBrand} placeholder="Производител на продукта"/>
                  </Form.Item>
                </Form.Item>
                </Col><Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <Form.Item className='prefildataplace' required>
                  <Form.Item name={['product', 'fetchname']} rules={[{ required: true }]}>
                  <Input size="large" onChange={handleProductData} placeholder="Каталожен номер на продукта"/>
                  </Form.Item>
                </Form.Item>
               
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <Form.Item className='prefildataplace' >
                <Space><Button type="primary" htmlType="submit" size="large" onClick={() => getProductData()}>
                Проверете
                    </Button>
                    <Button type="primary" className='button-scan' icon={<BarcodeOutlined />} size={'large'} onClick={() => setBarcodeOpened(true)}>Сканирай баркод</Button>
                    {/*<Button type="primary" className='button-scan' icon={<FileSearchOutlined />} size={'large'} onClick={() => OCRHandleAzure()}>OCR</Button>*/}
                    </Space>
                  </Form.Item>
              </Col>
            </Row>
            </Form>
            <Divider orientation="left"><Title level={4}>Данни за продукта</Title></Divider>
        <Form layout="vertical" form={form} onFinish={onFinish} validateMessages={validateMessages} scrollToFirstError='true'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <div>
                <Form.Item label="Име на продукта" required tooltip="Въведете името на продукта тук, напр. К-т съединител">
                  <Form.Item name={['product', 'name']} rules={[{ required: true }]} >
                  <Input size="large" onChange={handleProducName}/>
                  </Form.Item>
                </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <Form.Item label="Цена с вкл. ДДС" required tooltip="Въведете цена на продукта с ДДС">
                <Form.Item name={['product', 'price']} rules={[{ required: true, normalize: (value) => value.replace(',', '.') }]} >
                  <Input size="large" onChange={handleProducPrice}/>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <Form.Item label="Наличност" required tooltip="Въведете наличното количество">
                  <Form.Item name={['product', 'stock']} rules={[{ required: true }]}>
                  <Input size="large" onChange={handleProducStock}/>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
                <Form.Item label="Време за доставка" required tooltip="Въведете срок на доставка в работни дни, напр. 5">
                <Form.Item name={['product', 'delivery']} rules={[{ required: true }]} >
                  <Input size="large" onChange={handleProducDeliveryTime}/>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8} >
                <Form.Item label="Цена на доставка" required tooltip="Въведете цена на доставка">
                <Form.Item name={['product', 'delivery_cost']} rules={[{ required: true }]} >
                  <Input size="large" onChange={handleProducDeliveryCost}/>
                </Form.Item>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={8}>
              <Form.Item label="Вътрешен номер" required tooltip="Въведете уникален вътрешен номер на продукта, напр. 000000001, 000000002...">
                <Form.Item name={['product', 'external_id']} rules={[{ required: true }]}>
                  <Input size="large" onChange={handleProductExternalId}/>
                </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={24}>
            <Title level={4}>Подробности</Title>
            {isPricesLoading ? <div className="spin-center"><Spin size="large"/></div> : <Table columns={columnsPricesTable} rowKey='id' dataSource={prices} /> }
              </Col>
          </Row>
          <Divider orientation="left"></Divider>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={24}>
            <Form.Item label="Подробно описание" tooltip="Въведете описание на продукта">
            <Form.Item name={['product', 'description']} >
              <TextArea rows={4} onChange={handleProducDescription}/>
              </Form.Item>
              </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={24}>
            <Form.Item label="Приложение" tooltip="Въведете приложение на продукта, напр. Hyundai i30">
            <Form.Item name={['product', 'application']} >
            <Input size="large" onChange={handleProductApplication}/>
              </Form.Item>
              </Form.Item>
              </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" xs={24} sm={4} md={6} lg={12} xl={24}>
            <Form.Item label="Снимки" name={['product', 'photos']} tooltip="Добавете снимки на продукта" required rules={[
            ({ getFieldValue }) => ({
            validator(rule, value) {
              if (fileList.length > 0) {
                return Promise.resolve();
              }
              return Promise.reject('Полетата отбелязани със звездичка са задължителни!');
            },
            }),
         ]}>
              <Upload name="photos_field" action="https://images.vip-intercars.bg/uploadss" multiple="true" listType="picture-card" fileList={fileList} onPreview={handlePreview} onChange={handleChange} customRequest={handleUploadFile}>
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%',}} src={previewImage}/>
              </Modal>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left"></Divider>
          <Form.Item label="Оригинални номера и замени" name={['product', 'oem_numbers']} tooltip="Въведете оригинални номера и замени на продукта." required rules={[
            ({ getFieldValue }) => ({
            validator(rule, value) {
              if (getFieldValue('oem') && getFieldValue('oem').length) {
                return Promise.resolve();
              }
              return Promise.reject('Полетата отбелязани със звездичка са задължителни!');
            },
            }),
         ]}>
              <Form.List name="oem">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Row>
                        <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          {...restField}
                          name={[name, 'number']}
                        >
                          <Input size="large" style={{ width: 250 }} placeholder="Номер" />
                        </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          {...restField}
                          name={[name, 'manufacturer']}
                        >
                          <Select size="large"
                              
                              style={{ width: 250 }}
                              options={[
                                {value: "ABARTH",label: "ABARTH"},
                                {value: "ACURA",label: "ACURA"},
                                {value: "AIXAM",label: "AIXAM"},
                                {value: "ALFA ROMEO",label: "ALFA ROMEO"},
                                {value: "ARO",label: "ARO"},
                                {value: "ASTON MARTIN",label: "ASTON MARTIN"},
                                {value: "ASTRA",label: "ASTRA"},
                                {value: "AUDI",label: "AUDI"},
                                {value: "AUSTIN",label: "AUSTIN"},
                                {value: "AVIA",label: "AVIA"},
                                {value: "BEDFORD",label: "BEDFORD"},
                                {value: "BENTLEY",label: "BENTLEY"},
                                {value: "BMW",label: "BMW"},
                                {value: "BOBCAT",label: "BOBCAT"},
                                {value: "BOVA",label: "BOVA"},
                                {value: "BUICK",label: "BUICK"},
                                {value: "CADILLAC",label: "CADILLAC"},
                                {value: "CASE",label: "CASE"},
                                {value: "CATERPILLAR",label: "CATERPILLAR"},
                                {value: "CHEVROLET",label: "CHEVROLET"},
                                {value: "CHRYSLER",label: "CHRYSLER"},
                                {value: "CITROEN",label: "CITROEN"},
                                {value: "CLAAS",label: "CLAAS"},
                                {value: "CUMMINS",label: "CUMMINS"},
                                {value: "CUPRA",label: "CUPRA"},
                                {value: "DACIA",label: "DACIA"},
                                {value: "DAEWOO",label: "DAEWOO"},
                                {value: "DAF",label: "DAF"},
                                {value: "DAIHATSU",label: "DAIHATSU"},
                                {value: "DODGE",label: "DODGE"},
                                {value: "EVOBUS",label: "EVOBUS"},
                                {value: "FERRARI",label: "FERRARI"},
                                {value: "FIAT",label: "FIAT"},
                                {value: "FORD",label: "FORD"},
                                {value: "FSO",label: "FSO"},
                                {value: "GAZ",label: "GAZ"},
                                {value: "GEO",label: "GEO"},
                                {value: "GIGANT",label: "GIGANT"},
                                {value: "GMC",label: "GMC"},
                                {value: "GREAT WALL",label: "GREAT WALL"},
                                {value: "HONDA",label: "HONDA"},
                                {value: "HUMMER",label: "HUMMER"},
                                {value: "HYUNDAI",label: "HYUNDAI"},
                                {value: "IKARUS",label: "IKARUS"},
                                {value: "INFINITI",label: "INFINITI"},
                                {value: "INNOCENTI",label: "INNOCENTI"},
                                {value: "IRISBUS",label: "IRISBUS"},
                                {value: "IRIZAR",label: "IRIZAR"},
                                {value: "ISUZU",label: "ISUZU"},
                                {value: "IVECO",label: "IVECO"},
                                {value: "JAGUAR",label: "JAGUAR"},
                                {value: "JEEP",label: "JEEP"},
                                {value: "JOHN DEERE",label: "JOHN DEERE"},
                                {value: "KIA",label: "KIA"},
                                {value: "LADA",label: "LADA"},
                                {value: "LAMBORGHINI",label: "LAMBORGHINI"},
                                {value: "LANCIA",label: "LANCIA"},
                                {value: "LAND ROVER",label: "LAND ROVER"},
                                {value: "LEXUS",label: "LEXUS"},
                                {value: "LIAZ",label: "LIAZ"},
                                {value: "LINCOLN",label: "LINCOLN"},
                                {value: "LOTUS",label: "LOTUS"},
                                {value: "MAN",label: "MAN"},
                                {value: "MASERATI",label: "MASERATI"},
                                {value: "MAYBACH",label: "MAYBACH"},
                                {value: "MAZDA",label: "MAZDA"},
                                {value: "MCLAREN",label: "MCLAREN"},
                                {value: "MERCEDES-BENZ",label: "MERCEDES-BENZ"},
                                {value: "MG",label: "MG"},
                                {value: "MINI",label: "MINI"},
                                {value: "MITSUBISHI",label: "MITSUBISHI"},
                                {value: "NEOPLAN",label: "NEOPLAN"},
                                {value: "NISSAN",label: "NISSAN"},
                                {value: "OPEL",label: "OPEL"},
                                {value: "PETERBILT",label: "PETERBILT"},
                                {value: "PEUGEOT",label: "PEUGEOT"},
                                {value: "PLYMOUTH",label: "PLYMOUTH"},
                                {value: "PONTIAC",label: "PONTIAC"},
                                {value: "PORSCHE",label: "PORSCHE"},
                                {value: "RAM",label: "RAM"},
                                {value: "RENAULT",label: "RENAULT"},
                                {value: "ROLLS ROYCE",label: "ROLLS ROYCE"},
                                {value: "ROVER",label: "ROVER"},
                                {value: "RVI",label: "RVI"},
                                {value: "SAAB",label: "SAAB"},
                                {value: "SCANIA",label: "SCANIA"},
                                {value: "SDF",label: "SDF"},
                                {value: "SEAT",label: "SEAT"},
                                {value: "SETRA",label: "SETRA"},
                                {value: "SKODA",label: "SKODA"},
                                {value: "SMART",label: "SMART"},
                                {value: "SOLARIS",label: "SOLARIS"},
                                {value: "SSANGYONG",label: "SSANGYONG"},
                                {value: "STEYR",label: "STEYR"},
                                {value: "SUBARU",label: "SUBARU"},
                                {value: "SUZUKI",label: "SUZUKI"},
                                {value: "TATA",label: "TATA"},
                                {value: "TATRA",label: "TATRA"},
                                {value: "TEMSA",label: "TEMSA"},
                                {value: "TESLA",label: "TESLA"},
                                {value: "TOYOTA",label: "TOYOTA"},
                                {value: "TRABANT",label: "TRABANT"},
                                {value: "UAZ",label: "UAZ"},
                                {value: "URSUS",label: "URSUS"},
                                {value: "VAG",label: "VAG"},
                                {value: "VAN HOOL",label: "VAN HOOL"},
                                {value: "VOLVO",label: "VOLVO"},
                                {value: "VW",label: "VW"},
                                {value: "WARTBURG",label: "WARTBURG"},
                                {value: "YAMAHA",label: "YAMAHA"},
                                {value: "ZASTAVA",label: "ZASTAVA"},
                                {value: "ZAZ",label: "ZAZ"}
                              ]}
                            />
                        </Form.Item>
                        </Col></Row>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" size="large" onClick={() => add()} block icon={<PlusOutlined />}>
                      Добави оригинални и заменяеми номера
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
          </Form.Item>
          <div id="notification"></div>
          {isLoading ? <div id="progress" className="spin-center"><Spin /></div> : <div id="progress"></div>}
          <Divider orientation="left"></Divider>
          <Row className='last-row-form'>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button type="primary" htmlType="submit" size="large">
              Добавете
              </Button>
            </Col>
          </Row>
          </Form>
      </Content>
    </Layout> : 
    
    <Layout className='main'>
      <Header className='header'><img className="headerLogo" alt='Logo' src="https://bg.marketplace.intercars.eu/media/logo/default/logo_Marketplace.jpg"></img></Header>
      <Content style={style}>
      <Steps
        current={current}
        onChange={onChangeSteps}
        items={[
          {
            title: 'Избор на тип продукт',
            description: productTypeName,
          }
        ]}
      />
      <Divider orientation="left"><Title level={4}>Избор на тип продукт</Title></Divider>
      <Space
      direction='vertical'
        style={{
          width: '100%',
          textAlign: 'center'
        }}
      >
        <Button className='button-product_type_default' block onClick={() => {setProductType(true); setProductTypeCat('46'); setAttributeSet('21'); setProductTypeName('Outlet продукт'); setCurrent(1)}}>Outlet продукт<RightCircleOutlined /></Button>
      </Space>
      </Content>
    </Layout>
    }
    </React.Fragment>
  );
}
