import React, {Component } from "react";
import logo from './logo.svg';
import './App.css';
import Main from './Components/Main'
import Login from './Components/Login'
import { useState } from 'react';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogged: false  }
  }

  componentDidMount () {
   
    let getToken = sessionStorage.getItem('tokenDashboard');
    let khcode = sessionStorage.getItem('kh_code');
    let role = sessionStorage.getItem('role');

    if((getToken && getToken.length > 0) && khcode && role == "MARKETPLACE_SELLER") {
      this.setState({
        isLogged: true
      })
    }
  }


  render() {

    var {isLogged} = this.state;

    if(!isLogged) {
      return (
        <div style={{display: 'flex', bottom: 0, justifyContent: 'center'}}>
            <Login />
        </div>)
    }
    else {

    return (
      <React.Fragment>
        <Main/>
    </React.Fragment>
    );

  }

  }
}

export default App;
