import React from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import { Spin, Button, Alert, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import '../Login.css';
import i18next from "i18next";
import common_en from "../translations/en/common.json";
import common_bg from "../translations/bg/common.json";
import common_ro from "../translations/ro/common.json";
import common_lt from "../translations/lt/common.json";
import common_lv from "../translations/lv/common.json";
import common_hr from "../translations/hr/common.json";
import { initReactI18next, useTranslation } from "react-i18next";
const resources = {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    bg: {
        common: common_bg
    },
    lv: {
        common: common_lv
      },
      lt: {
        common: common_lt
      },
      hr: {
        common: common_hr
      },
    ro: {
        common: common_ro
    }
  }

  
  i18next
  .use(initReactI18next)
  .init({resources, lng: 'bg'});

export default function Login(props) {

    const { t, i18n } = useTranslation('common');
    const [username, setUsername] = React.useState('');
    const [waitingMessage, setwaitingMessage] = React.useState(false);

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const [password, setPassword] = React.useState('');

    const handleChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const loginCheck = () => {

            setwaitingMessage(true)
  
          const dataLogin = { "username": username, "password": password };
  
          const reqLogin = async () => {

            const responseData = await axios.post('https://images.vip-intercars.bg/checklogin', dataLogin, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest' //the token is a variable which holds the token
                }}).then((results) => {

                    if(results.data.length > 0 && results.status === 200){
                            sessionStorage.setItem('tokenDashboard', results.data);

                            const userData = async () => { await axios.post('https://images.vip-intercars.bg/verifyToken', {"token":results.data}, {headers: {
                                'X-Requested-With': 'XMLHttpRequest' //the token is a variable which holds the token
                            }}).then((resultsVerify) => { 
                                let customAttributes = resultsVerify.data.custom_attributes;
                                var kh_code = ""
                                var role = ""
                                
                                customAttributes.forEach((value,index) => { if(value.attribute_code === "kh_code") {kh_code = value.value}; if(value.attribute_code === "user_role") {role = value.value}})

                                sessionStorage.setItem('username', resultsVerify.data.email);
                                sessionStorage.setItem('firstname', resultsVerify.data.firstname);
                                sessionStorage.setItem('lastname', resultsVerify.data.lastname);
                                sessionStorage.setItem('kh_code', kh_code);
                                sessionStorage.setItem('role', role);
                            }).catch(error => { sessionStorage.removeItem('tokenDashboard'); console.log(error.message) })}

                            userData();
                            
                            render(<Alert message={t('login.login_message_sucess')} type="success" />, document.getElementById('message-status'));
                            setwaitingMessage(false)
                            
                            setTimeout(function(){
                            window.location.reload(true);
                        }, 2000);
                    } else if(results.data.status === 401) {
                        setwaitingMessage(false)
                        render(<Alert message={t('login.login_message_error')} type="error" />, document.getElementById('message-status'));
                    }
                }).catch(error => {console.log(error);
                    setwaitingMessage(false)
                    render(<Alert message="Грешка!" type="error" />, document.getElementById('message-status'));
                })

           }

           reqLogin()

    };

    if(waitingMessage){
        render(
        <Spin size="large" />
        , document.getElementById('message-status'))
    }


    return (
        <>
        <div className="Login">
        <div id="logo-login-form"><img id="logo-login-form-img" alt='Logo' src="https://bg.marketplace.intercars.eu/media/logo/default/logo_Marketplace.jpg"></img></div>
        <div id="message-status"></div>
        <div id="login-username">
        <Form.Item
                name="username"
                rules={[{ required: true, message: 'Моля, въведете потребителско име!' }]}
                            >
                <Input size="large" onChange={handleChangeUser} prefix={<UserOutlined />}/>
                </Form.Item></div>
        <div id="login-password">    
        <Form.Item
            name="password"
            rules={[{ required: true, message: 'Моля, въведете паролаd!' }]}
            >
            <Input.Password size="large" onChange={handleChangePass} prefix={<LockOutlined />}/>
            </Form.Item></div>

        <div id="login-submit">
             <Button type="primary" htmlType="submit" size="large" onClick={() => loginCheck()}>
                {t('login.login_button')}
            </Button>
        </div>
        </div>
        </>
    )

}